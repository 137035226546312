import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { joinedPostList } from "../../utils/posts"
import PostListContent from "./postListContent"
import "./postList.less"

export type StaticPostNode = {
  title: string
  link: string
  description?: string
  featuredImage?: any
}

const PostList = () => {
  const {
    allContentfulBlogPost: { edges: contentfulEdges },
  } = useStaticQuery(graphql`
    query {
      __typename
      allContentfulBlogPost {
        __typename
        edges {
          node {
            ...ContentfulNodeFields
          }
        }
      }
    }
  `)

  const staticPosts = []

  const posts = [...staticPosts, ...(joinedPostList(contentfulEdges) || [])]

  return <PostListContent posts={posts} />
}

export default PostList
